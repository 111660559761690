@font-face {
    font-family: 'quicksand-vf';
    src: url('../assets/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* DEFINING COMMON VARIABLES */
:root {
    --light-blue: #00bfff;
    --light-blue_hover: #1188af;
    --ambulytics-green: #01959E;
    --white_hover: #F2F2F2;
    --orange: #f89925;
    --orange_hover: #df7e07;
    --light-grey: #ced4da;
    --light-grey_hover: #a8adb3;
    --dark-grey: #555;
    --main-font: 'Quicksand', sans-serif;
    --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

body {
    margin: 0;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


/* INPUT TEXT BOXES */

.form-group {
    margin-bottom: 1vh;
    justify-content: center;
}

.input-form {
    /* display: flex; */
    border: 1px solid var(--light-grey);
    font-weight: 400;
    border-radius: 5px;
    height: 3vh;
    padding: .375rem .75rem;
    background-color: white;
    font-family: var(--main-font);
    width: 90%;
}

.field {
    margin: 1vh;
    font-size: 14px;
}

.input-box {
    display: flex;
    width: 200px;
    max-width: 100%;
    justify-content: space-between;
    object-fit: contain;
    margin-right: 0;
}

.search-box {
    display: flex;
    flex-direction: row;
    margin: 0 5px;
}

.input-form:focus {
    border: 1px solid var(--light-grey_hover);
    background-color: white;
}

@media only screen and (max-width: 900px) {

    .input-box,
    .search-box,
    .input-form {
        justify-content: center;
    }

    .input-box,
    .search-box {
        justify-content: center;
        padding: 0;
    }

    .input-form {
        height: 3vh;
        width: auto;
    }
}


/* INPUT ELEMENTS */

/* buttons */
.btn {
    margin: 0;
    /* display:block; */
    display: flex;
    /* justify-content: center; */
    color: white;
    padding: 1vh;
    text-decoration: none;
    border: 1px solid transparent;
    font: 1rem / 1.5em var(--main-font);
    border-radius: .1rem;
    cursor: pointer;
}

.btn-primary {
    background-color: var(--ambulytics-green);
}

.btn-primary:hover {
    background-color: var(--ambulytics-green);
}

.btn-secondary {
    background-color: var(--light-blue);
}

.btn-secondary:hover {
    background-color: var(--light-blue_hover);
}

.submit {
    width: 100%;
}

.link {
    text-decoration: none;
}


/* IMAGES */

/* the logo images of different companies with which we create pipelines */
.image {
    width: 90%;
    height: 90%;
    border-radius: 0.5rem;
}

.image:hover {
    opacity: 0.3;
}

/* the ambulytics logo appearing at the top of some pages */
.main-logo-image {
    max-width: 5vw;
    margin-bottom: -1vw;
    /* the dataconnector logo has a little blank space at the bottom */
    min-width: 150px;
    /* this makes it so that the logo image doesnt get super cute and tiny on small screens */
}

.setup-page .main-logo-image {
    min-width: 120px;
}

/* the ambulytics logo on nav bar */
.nav-logo-img {
    width: auto;
    height: 35px;
}

.menu-icon {
    display: none;
    visibility: hidden;
}

@media only screen and (max-width: 900px) {
    .nav-logo-img {
        height: 25px;
        margin-left: 10px;
    }

    .menu-icon {
        display: block;
        font-size: large;
        color: white;
        visibility: visible;
    }

    .mega-menu {
        position: unset !important;
    }

    .img-lg {
        max-width: 50%;
    }

    .main-logo-image {
        margin-top: 0;
    }
}

.img-md {
    object-fit: contain;
    display: inline-flex;
    width: 58px;
    border-radius: 5%;
}

.img-lg {
    object-fit: contain;
    display: inline-flex;
    width: 80%;
    border-radius: 5%;
}



/* ELEMENT ORGANIZATION */

.placeholder {
    display: flex;
    width: 90%;
    height: 90%;
    padding: -10%;
    border-radius: 25px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    /*this makes it so that the icons on the home page stay in nice rows*/
}

.col-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.margin-5 {
    margin: 5vh;
}

.margin-3 {
    margin: 3vh;
}

.margin-1 {
    margin: 1vh;
}

.margin-2 {
    margin: 1rem;
}

.margin--3 {
    margin: -3vh;
}

.padding-3 {
    padding: 3vh;
}

.align-right {
    display: flex;
    float: right;
    justify-content: right;
}

.align-center {
    display: flex;
    justify-self: center;
    justify-content: center;
}

.aligns-center {
    align-content: center;
}

.bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

.top {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
}

.not-sides {
    margin-right: 0;
    margin-left: 0;
}

.login {
    margin: 50px auto 0 auto;
    max-width: 30em;
    border-radius: 10px;
    padding: 20px 30px;
}

.box-container {
    margin: 3vh;
}

.main-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
    justify-content: center;
}

.setup-page .main-container,
.setup-page .main-container .login {
    margin-top: 0;
}

.setup-page .main-container .margin-2 {
    margin: 0.5rem 0;
}

.setup-page .main-container h1,
.setup-page .main-container h4 {
    margin: 0;
}

@media only screen and (max-width: 900px) {
    .main-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .col-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;
    }
}

@media (min-width: 1200px) {
    .main-container {
        align-items: center;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 900px) {
    .main-container {
        align-items: center;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
    }
}

.bio {
    display: flex;
    flex-direction: column;
    margin: 1vw;
}

.bio-list {
    flex-direction: row;
}

/* TEXT */

.text {
    text-align: center;
    font-family: var(--main-font);
}

.costpermonth {
    font-size: 5vh;
    color: green;
}

.error {
    color: rgb(255, 75, 75);
}

.success {
    color: #4bb543;
}

.title {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--dark-grey);
}

.subtitle {
    font-size: 1.25em;
    font-weight: 300;
    margin-bottom: 1em;
}

.subheader {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    font-size: 1.25rem;
}

.subheader-detail {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    font-size: 1.0rem;
    font-style: italic;

}

.link-txt {
    color: var(--light-blue);
    /* font-weight: 500; */
}

.link-txt:hover {
    color: var(--light-blue_hover);
}

@media only screen and (max-width: 900px) {
    .subtitle {
        font-size: 1em;
    }
}



/* NAVIGATION */

.topnav {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
}

.nav-links {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.nav-links>li {
    line-height: 52px;
}

.nav-link,
.mega-nav-link,
.mega-footer-link {
    padding: 0px 12px;
    margin: 0px 4px;
    /* color: #fff; */
    color: var(--dark-grey);
    text-decoration: none;
    font-family: var(--main-font);
    font-size: 1.4rem;
    position: relative;
}

.nav-link::after {
    position: absolute;
    content: '';
    bottom: -6px;
    left: 0;
    width: 0;
    height: 2px;
    /* background-color: #fff; */
    background-color: var(--dark-grey);
    opacity: .8;
    transition: width 0.3s ease-in-out;
}

.active-link {
    padding: 0px 12px;
    margin: 0px 4px;
    color: var(--dark-grey);
    text-decoration: none;
    font-family: var(--main-font);
    font-size: 1.4rem;
    position: relative;
}


.active-link::after {
    position: absolute;
    content: '';
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--dark-grey);
    opacity: .8;
    transition: width 0.3s ease-in-out;
}


.active-link.nav-link::after,
.nav-link:hover::after {
    width: 100%;
}

.mega-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    /* background-color: #fff; */
    background-color: #000;
    z-index: 10;
    display: none;
    text-align: left;
}

.nav-links li:hover .mega-menu {
    display: block;
}

.mega-menu .menu-list-header {
    font-size: 24px;
    font-weight: 600;
    color: var(--ambulytics-green);
    text-transform: uppercase;
    line-height: 32px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid var(--ambulytics-green);
}

.mega-menu .mega-nav-link {
    color: var(--dark-grey) !important;
    padding: 0;
    line-height: 80px;
}

.mega-menu .mega-footer-link {
    border-right: 1px solid #959595;
    line-height: 60px;
    padding: 0 30px;
}

.mega-menu .mega-footer-link:last-child {
    border: 0;
}

/* Add an active class to highlight the current page */
.is-active {
    display: hidden;
    color: var(--light-blue);
}

.hamburger-menu {
    display: none !important;
    color: var(--ambulytics-green) !important;
}

.topNavHeader {
    color: var(--ambulytics-green);
    display: inline-flex;
    margin: 4px 0 0 10px;
}


/* When the screen is less than 600 pixels wide, hide all links, except for the selected one. Show the link that contains should open and close the topnav (.menu-icon) */
@media screen and (max-width: 900px) {
    .topnav .nav-link {
        display: none;
    }

    .nav-links>li {
        line-height: 30px;
    }

    .hamburger-menu {
        display: inline-flex !important;
    }

    .topNavHeader {
        display: none;
    }

    .topnav .menu-icon {
        float: right;
        display: block;
    }

    .topnav {
        padding: 0;
        height: 7vh;
        min-height: 40px;
        overflow-y: auto;
        justify-content: space-between;
    }

    .is-active {
        color: white;
        text-decoration: none;
        font-family: var(--main-font);
        margin-left: 5vw;
        display: block;
    }

    .active-link {
        display: none;
    }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon.
This class makes the topnav display the links vertically on small screens */
@media screen and (max-width: 900px) {
    .topnav.responsive {
        height: 45vh;
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
    }

    .topnav.responsive .menu-icon {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
    }

    .topnav.responsive .nav-link {
        float: none;
        display: block;
        text-align: left;
        font-size: 1rem;
        padding-top: 1.5vh;
    }

    .topnav.responsive .nav-links {
        justify-content: flex-start;
        flex-direction: column;
        height: 90%;
        order: 3;
        flex: 0 0 100%;
        border-top: 1px solid #cfcfcf;
    }

    .topnav.responsive .is-active {
        visibility: hidden;
    }

    .topnav.responsive .active-link {
        float: none;
        display: block;
        text-align: left;
        font-size: 1rem;
        padding-top: 1.5vh;
    }

    .nav-links li:hover .mega-menu {
        display: none;
    }

    .topnav.responsive .nav-links li:hover .mega-menu {
        display: block;
    }
}

/*menu icon*/
.menu-icon {
    display: inline-block;
    cursor: pointer;
    /* margin: 1rem; */
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.alert-link {
    margin-left: 5px;
    color: rgb(50, 50, 50);
}

.btn-base {
    font-family: var(--main-font);
    cursor: pointer;
}

.profile-icon {
    width: 25px;
}

.nav-2 {
    display: flex;
    margin-right: 8px;
}

.flex-row {
    display: flex;
}

.justify-evenly {
    justify-content: space-evenly;
}

.justify-center {
    justify-content: center;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.gap-100 {
    gap: 100px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-item-30 {
    width: 30%;
}

.flex-item-1 {
    flex: 1;
}

.w-1 {
    width: 300px;
}

.stepper-image {
    width: 35rem;
}

.connector-img {
    display: inline-flex;
    width: auto;
    max-width: calc(100% - 10px);
    border-radius: 5px;
}

.setup-page .connector-img.showcase-img {
    width: 270px;
    border-radius: 10px;
}

.connector-with-ribbon {
    position: relative;
}

.connector-with-ribbon .coming-soon-ribbon {
    position: absolute;
    top: 2px;
    right: 3px;
    max-width: 45%;
    max-height: 100%;
}

.header-content {
    background: var(--ambulytics-green);
    position: relative;
    padding-top: 4vh;
    color: #fff;
    font-weight: 500;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    overflow-x: clip;
}

.header-content::after {
    content: '';
    position: absolute;
    bottom: -19.95vmax;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-top: 20vmax solid var(--ambulytics-green);
}

.header-content .header-title {
    font-size: 108px;
    font-weight: 500;
    margin: 0;
}

.header-content .header-text {
    font-size: 36px;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.home-container .three-step-easy-row {
    padding: 12vmax 2vw 7vmax;
    flex-flow: row wrap
}

.home-container .three-steps {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.home-container .three-steps .three-steps-label svg {
    color: #4bb543;
    font-size: 2rem;
}

.home-container .three-steps .three-steps-label span:last-child {
    font-size: 24px;
    font-weight: 500;
    color: var(--dark-grey);
    margin-top: 4px;
}

.header-content .header-text p {
    margin: 0;
}

.header-free-trial {
    margin: 7rem 0;
}

.header-stepper {
    margin-top: 60px;
}

.m-1 {
    margin: 5px;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mx-1 {
    margin: 0 3rem 0 3rem;
}

.seperator-line {
    position: relative;
    border-top: 1px solid #AFAFAF;
    margin: 1.5rem 5px;
}

.seperator-label {
    position: absolute;
    background-color: #fff;
    top: -10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    font-size: 17px;
    font-weight: 700;
    color: #AFAFAF
}

.source-destination-row {
    justify-content: space-between;
}

.sources {
    position: relative;
    font-weight: 200;
}

.sources-header {
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-grey);
}

.FormGroup {
    margin: 5px 0px 25px 0px;
    padding: 0;
    border-style: none;
    background-color: #fff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(60, 60, 82, 0.06), 0 2px 5px rgba(0, 0, 0, 0.153), inset 0 1px 0 #fff;
    border-radius: 4px;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #fff;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}

.size-10 {
    font-size: 10px;
}

.size-12 {
    font-size: 12px;
}

.size-14 {
    font-size: 14px;
}

.free-trial-icon {
    position: relative;
}

.free-trial-icon img {
    position: absolute;
    right: calc(50% - 275px);
    top: -6px;
}

.setup-page .free-trial-icon img {
    right: -6px;
}

@media screen and (max-width: 1400px) {
    .header-content .header-title {
        font-size: 85px;
    }

    .header-content .header-text {
        font-size: 28px;
    }

    .header-content .header-text a {
        margin-top: 2vmax !important;
    }

    .header-content::after {
        bottom: -15.95vmax;
        border-top: 16vmax solid var(--ambulytics-green);
    }
}

@media screen and (max-width: 1050px) {
    .header-content {
        padding: 0;
    }

    .header-content .header-title {
        font-size: 62px;
    }

    .header-content .header-text {
        font-size: 20px;
    }

    .header-content .header-text a {
        padding: 2px 30px !important;
        font-size: 14px !important;

    }

    .nav-link,
    .mega-menu .menu-list-header {
        font-size: 1.2rem;
    }

    .mega-menu .mega-nav-link {
        font-size: 1.2rem;
        line-height: 40px;
    }

    .mega-menu .mega-footer-link {
        font-size: 1.2rem;
        line-height: 40px;
        padding: 0 15px;
    }

    .home-container .three-step-easy-row {
        padding: 17vmax 2vw 12vmax;
        justify-content: center !important;
    }

    .sources-header {
        top: -3rem;
    }
}

@media screen and (max-width: 850px) {
    .header-content .header-title {
        font-size: 50px;
    }

    .header-content .header-text {
        font-size: 16px;
    }

    .setup-page .connector-img.showcase-img {
        width: 150px;
    }
}

@media screen and (max-width: 959px) {
    .source-destination-row {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 620px) {
    .free-trial-icon img {
        position: absolute;
        right: -5px;
        top: -6px;
    }

    .source-destination-row {
        grid-gap: 20px;
        gap: 20px;
    }
}

@media screen and (max-width: 450px) {
    .header-content .header-title {
        font-size: 30px;
    }

    .header-content .header-text {
        font-size: 13px;
    }

    .header-content .header-text a {
        padding: 0 15px !important;
    }

    .source-destination-row {
        margin: 0 1rem !important;
    }

    .setup-page .connector-img.showcase-img {
        width: 120px;
    }
}

.text-left {
    text-align: left;
}

.footer {
    background-color: var(--ambulytics-green);
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    color: #fff;
    max-width: 900px;
    margin: auto;
}

.footer-link {
    text-decoration: none;
    color: #fff;
}

.recharts-text {
    fill: var(--ambulytics-green);
}

.dx-texteditor {
    height: 40px;
    border: 1.5px solid #ddd;
    border-radius: 4px;
}

/* .recharts-cartesian-axis-tick-value */

.insight-card-icon {
    height: 30px;
    vertical-align: middle;
    padding: 10px 0 40px 0;
}

.insight-card:hover .insight-card-icon {
    transform: scale(1.25);
}

.Toastify__toast--success {
    text-align: left;
}