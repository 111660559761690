.login {
    margin: 5% auto 0 auto;
    max-width: 30em;
    border-radius: 10px;
    padding: 20px 30px;
}

.field {
    width: 100%; 
}

.loginLabel {
    text-align: center;
    padding: 15px;
    font-weight: normal;
    
}

.submit {
    background-color: #00bfff !important;
    color: #fff !important;
    padding: 5px 0 !important;
    font-size: 1em !important;
    text-transform: none !important;
}

.submit:hover {
    background-color: #00bfff;
}

.forgot {
    text-align: center;
    padding: 20px;
}

.forgot a {
    text-decoration: none;
    color: #00bfff;
}